import type { FC } from 'react'
import { useEffect, useState } from 'react'
import type { MouseEvent } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { datePikerLabelClassName } from '@components/mainPage/mainBlock/searchTrains/search/searchForm/constants/classNames'
import { UilAngleLeft } from '@iconscout/react-unicons'
import { textLargeMediumStyles } from '@styles/typography/text'
import closePickerWithoutSave from '@utils/datePicker/closePickerWithoutSave'
import getIsDepartureInputActive from '@utils/datePicker/getIsDepartureInputActive'
import getSearchFormPickerInputs from '@utils/datePicker/getSearchFormPickerInputs'

type Props = {
  closeAction: () => void
}

const antdSaveButtonClick = (e: MouseEvent<HTMLSpanElement>) =>
  (
    (e.target as HTMLSpanElement)
      .closest('.ant-picker-panel-container')
      ?.querySelector('.ant-picker-ok button') as HTMLButtonElement
  )?.click()

const MobileHeader: FC<Props> = ({ closeAction }) => {
  const { t } = useTranslation('Search form')
  const departureCaptionText = t('departureDate')
  const returnCaptionText = t('returnDate')
  const pickerInputsNodes = getSearchFormPickerInputs()
  const inputsLength = pickerInputsNodes.length
  const [isDepartureActive, setIsDepartureActive] = useState(false)

  useEffect(() => {
    setIsDepartureActive(getIsDepartureInputActive(pickerInputsNodes))
  }, [pickerInputsNodes])

  const clickHandler = (e: MouseEvent<HTMLSpanElement>) => {
    antdSaveButtonClick(e)
    if (!isDepartureActive) {
      const inputNode = document.querySelector(
        `.${datePikerLabelClassName} .ant-picker-input:not(.ant-picker-input-active) input`
      ) as HTMLInputElement
      inputNode.focus()
    }

    setIsDepartureActive(value => !value)
  }

  return (
    <Wrapper>
      <Button onClick={closePickerWithoutSave(closeAction)} />
      <DirectionLabel $active={isDepartureActive} onClick={!isDepartureActive ? clickHandler : undefined}>
        {departureCaptionText}
      </DirectionLabel>
      {inputsLength === 2 && (
        <DirectionLabel $active={!isDepartureActive} onClick={isDepartureActive ? clickHandler : undefined}>
          {returnCaptionText}
        </DirectionLabel>
      )}
    </Wrapper>
  )
}

const Wrapper = styled.header`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin: 32px 16px 20px 16px;
`

const Button = styled(UilAngleLeft)`
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  cursor: pointer;
  fill: ${p => p.theme.colors.fontSecondary};
`

const DirectionLabel = styled.span<{ $active: boolean }>`
  ${textLargeMediumStyles};
  color: ${p => (p.$active ? p.theme.colors.fontMain : p.theme.colors.fontSecondary)};
  text-decoration: ${p => (p.$active ? 'underline' : 'none')};
  text-decoration-color: ${p => p.theme.colors.primary};
  text-decoration-thickness: 2px;
  text-underline-offset: 5px;
  cursor: ${p => (p.$active ? 'default' : 'pointer')};

  &:last-child {
    margin-inline-start: 24px;
  }
`

export default MobileHeader
