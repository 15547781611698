import type { FC, ReactNode } from 'react'
import { useEffect, useRef } from 'react'
import { createPortal } from 'react-dom'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { SearchFormKeys } from '@components/mainPage/mainBlock/searchTrains/search/searchForm/constants/form'
import InformationBlockHeader from '@components/popups/informationBlock/InformationBlockHeader'
import { modalsZIndexes } from '@constants/zIndexes/modals'
import useMediaQuery from '@hooks/useMediaQuery/useMediaQuery'
import useScrollControl from '@hooks/useScrollControl'
import { mediaQueries } from '@themes/mediaQueries'
import Title from '@typography/Title'

export type StationSelectProps = {
  arrivalStation: ReactNode
  departureStation: ReactNode
  focusedField: SearchFormKeys.arrival | SearchFormKeys.departure | undefined
  onClose: () => void
}

export const MobileModalStations: FC<StationSelectProps> = ({
  arrivalStation,
  departureStation,
  focusedField,
  onClose,
}) => {
  const isMobile = useMediaQuery(mediaQueries.mobile)
  const { t } = useTranslation('Search form')
  useScrollControl(isMobile && !!focusedField)
  const prevFocusedField = useRef<StationSelectProps['focusedField']>(focusedField)

  useEffect(() => {
    if (focusedField && !prevFocusedField.current) {
      const input = document.createElement('input')
      document.body.appendChild(input)
      input.style.position = 'absolute'
      input.style.top = '0'
      input.style.opacity = '0'
      input.focus()

      setTimeout(() => {
        document.getElementById(focusedField)?.focus()
        document.body.removeChild(input)
      }, 0)
    }

    prevFocusedField.current = focusedField
  }, [focusedField])

  if (!isMobile || !focusedField) return null

  return createPortal(
    <Wrapper>
      <InformationBlockHeader close={onClose}>
        <Title level={6}>{t('chooseARoute')}</Title>
      </InformationBlockHeader>
      {departureStation}
      {focusedField === SearchFormKeys.arrival && arrivalStation}
    </Wrapper>,
    document.body
  )
}

const Wrapper = styled.div`
  position: fixed;
  inset: 0;
  z-index: ${modalsZIndexes.default};
  background: ${p => p.theme.colors.backgroundMain};
`
